import React, {Component} from 'react';
import {observer} from "mobx-react";
import Styles from './AvatarTag.module.scss'
import {Image} from "react-bootstrap";
import {THUMBNAIL_BASE_URL} from "../../../const/AppConst";
import axios from "axios";

@observer
class AvatarTag extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let {tag, callback} = this.props;
        return (
            <div className={Styles.AvatarTag}
                 onClick={()=>{
                     if(tag.outerUniqueKey.startsWith('FC2-ACTOR-')) {
                         window.open(`https://db.msin.jp/page/actress?id=${tag.outerUniqueKey.replace('FC2-ACTOR-','')}`)
                     }else if(tag.outerUniqueKey.startsWith('AVDBS-ACTOR-')) {
                         window.open(`https://www.avdbs.com/menu/actor.php?actor_idx=${tag.outerUniqueKey.replace('AVDBS-ACTOR-','')}`)
                     }
                 }}
            >
                <div className={Styles.Avatar}
                     onDragEnter={e => {
                         e.preventDefault();
                     }}
                     onDragOver={e => {
                         e.stopPropagation();
                         e.preventDefault();
                     }}
                     onDrop={e => {
                         e.preventDefault();
                         e.stopPropagation()

                         if (e.dataTransfer.files.length > 0) {
                             let formData = new FormData();
                             for (const [key, value] of Object.entries(tag)) {
                                 if (value)
                                     formData.append(key, value);
                             }
                             formData.append('file', e.dataTransfer.files[0])
                             axios.post(`/tag/thumbnail/insert`,
                                 formData,
                                 {responseType: "json"}
                             ).then(({data}) => {
                                 callback && callback()
                             });
                         }

                     }}
                >
                    <Image src={`${THUMBNAIL_BASE_URL}/tag/${tag?.id}/${tag?.thumbnailPath}`}/>
                </div>
                <div className={Styles.Name}>
                    {tag?.translateName ? tag?.translateName : tag?.name}
                </div>
            </div>
        );
    }
}

AvatarTag.propTypes = {};

export default AvatarTag;