import React from 'react';
import {observer} from 'mobx-react';
import Styles from './SideBar.module.scss';
import {MdHome, MdImage, MdOutlinePlaylistAdd, MdVideoLibrary} from "react-icons/md";
import {Button} from "react-bootstrap";
import {navigate} from "../../helper/HistoryHelper";
import {FaTag, FaUser} from "react-icons/fa";

@observer
class SideBar extends React.Component {

    render() {
        return (
            <div className={Styles.SideBar}>

                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/home`)
                        }}
                >
                    <MdHome size={`1.5rem`}/>
                    홈
                </Button>
                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/actor/manage`)
                        }}
                >
                    <FaUser size={`1.5rem`}/>
                    관리
                </Button>
                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/media/manage`)
                        }}
                >
                    <MdVideoLibrary size={`1.5rem`}/>
                    관리
                </Button>
                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/wait/list`)
                        }}
                >
                    <MdOutlinePlaylistAdd size={`1.5rem`}/>
                    업로드
                </Button>
            </div>
        );
    }
}

export default SideBar;
