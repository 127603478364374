import React, {Component} from 'react';
import {observer} from 'mobx-react';

import ContentWrap from "../../components/layout/ContentWrap";


@observer
class MainView extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }


    render() {

        return (
            <ContentWrap>

            </ContentWrap>
        );
    }
}

MainView.propTypes = {};

export default MainView;
