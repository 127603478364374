import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {mediaStore, tagMediaStore} from "../../store/MediaStore";
import ContentWrap from "../../components/layout/ContentWrap";
import InfiniteScroll from "react-infinite-scroller";

import {
    Button,
    Col,
    Container,
    FormControl,
    InputGroup,
    Nav,
    Row,
    Tab,
    Tabs,
    Form,
    Dropdown,
    FormLabel
} from "react-bootstrap";
import Styles from './ActorManageView.module.scss'
import axios from "axios";
import Player from "../../components/media/Player";
import {playerStore} from "../../store/PlayerStore";
import {MdClosedCaption, MdDeleteOutline, MdImage, MdVideoLibrary} from "react-icons/md";
import {IoMdImages} from "react-icons/io";
import {spriteThumbnailStore} from "../../components/modal/SpriteThumbnailModal";
import {actorStore} from "../../store/TagStore";
import {Img} from "react-image";
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import Media from "../../components/media/Media";
import moment from "moment";
import notificationStore from "../../store/NotificationStore";

const SearchDropdown = observer(({title, id, store, trueLabel = '있음', falseLabel = '없음'}) => {
    let searchCondition = store.searchCondition;
    let value = searchCondition.get(id);
    return(
        <div>
            {title}
            <Dropdown className="d-inline mx-2" >
                <Dropdown.Toggle size={'sm'} variant={'outline-secondary'} as={Button}>
                    {
                        value === undefined ? '전체' : value ? trueLabel:falseLabel
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>{searchCondition.set(id, undefined)}}>전체</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{searchCondition.set(id, true)}}>{trueLabel}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{searchCondition.set(id, false)}}>{falseLabel}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
});

@observer
class ActorManageView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tag: undefined
        }

    }

    componentDidMount() {
        let store = actorStore;
        store.scrollRef.current.scrollTo(0, store.scrollTop)
        if(store.isInit){
            store.debounceRefresh();
        }
    }


    render() {
        let store = actorStore;
        let tag = this.state.tag;
        return (
            <ContentWrap>
                <div className={Styles.SearchBar}>
                    <div className={`p-0 d-flex justify-content-between`}>
                        <div className={`d-flex text-nowrap`}>
                            <SearchDropdown title={<MdDeleteOutline size={`1.5rem`}/>} id={'isDeleted'} store={store} trueLabel={'삭제'} falseLabel={'사용'}/>
                            <SearchDropdown title={<MdImage size={`1.5rem`}/>} id={'haveThumbnail'} store={store}/>

                        </div>
                        <div className={`d-flex flex-grow-1 text-nowrap`}>
                            <div className={`d-flex align-items-center me-2`}>


                            </div>
                            <InputGroup>
                                <FormControl size={'sm'}
                                             value={this.state.keyword}
                                             onChange={e => {
                                                 this.setState({keyword: e.target.value});
                                             }}
                                             onKeyDown={e => {
                                                 if (e.key === 'Enter') {
                                                     store.searchCondition.set("keyword", this.state.keyword)
                                                     store.debounceFetch(true);
                                                 }
                                             }}
                                />
                                <Button variant={"outline-light"} size={'sm'}
                                        onClick={() => {
                                            store.searchCondition.set("keyword", this.state.keyword)
                                            store.debounceFetch(true);
                                        }}
                                >
                                    검색
                                </Button>
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={`d-flex flex-grow-1 overflow-hidden`}>

                    <div className={Styles.List}
                         ref={store.scrollRef}
                         onScroll={store.onScrollHandler}
                    >
                        <InfiniteScroll
                            loadMore={(page) => {
                                store.debounceFetch(false)
                            }}
                            hasMore={store.tags.length < store.count}
                            loader={
                                <div key={"a"}>
                                </div>
                            }
                            useWindow={false}
                            threshold={5}
                        >

                            {
                                !store.isFetching && store.tags.length < 1 &&
                                <div className={`align-items-center justify-content-center d-flex p-5`}>
                                    검색 결과가 없습니다.
                                </div>
                            }
                            {
                                store.tags.map(tag => {
                                    return (
                                        <div className={`${Styles.File} ${this.state.tag?.id === tag.id ? Styles.Active : ''} ${!tag.isActive ? Styles.Deleted : ''}`}
                                             onClick={()=>{
                                                 this.setState({tag})
                                                 tagMediaStore.searchCondition.set('tagId', tag.id)
                                                 tagMediaStore.debounceRefresh(true);
                                             }}
                                        >
                                            <div className={`d-flex justify-content-between align-items-center`}>
                                                <div className={Styles.Icons}>
                                                    {
                                                        tag.thumbnailPath &&
                                                        <MdImage/>
                                                    }
                                                </div>
                                                <div className={`d-flex align-items-center`}>
                                                    <MdVideoLibrary className={`me-2`}/>
                                                    {
                                                        tag.mediaCount
                                                    }
                                                </div>
                                            </div>
                                            <div className={Styles.Name}>
                                                {tag.translateName ? tag.translateName : tag.name}
                                            </div>

                                        </div>
                                    )
                                })
                            }

                        </InfiniteScroll>
                    </div>
                    {
                        tag &&
                            <div className={Styles.Content} >
                                <div className={`d-flex w-100`}>
                                    <div>
                                        <div className={`d-flex`} style={{width:300,height:"auto"}}
                                             onDragEnter={e => {
                                                 e.preventDefault();
                                             }}
                                             onDragOver={e => {
                                                 e.stopPropagation();
                                                 e.preventDefault();
                                             }}
                                             onDrop={e => {
                                                 e.preventDefault();
                                                 e.stopPropagation()

                                                 if (e.dataTransfer.files.length > 0) {
                                                     let formData = new FormData();
                                                     for (const [key, value] of Object.entries(tag)) {
                                                         if (value)
                                                             formData.append(key, value);
                                                     }
                                                     formData.append('file', e.dataTransfer.files[0])
                                                     axios.post(`/tag/thumbnail/insert`,
                                                         formData,
                                                         {responseType: "json"}
                                                     ).then(({data}) => {
                                                         this.setState({tag:data})
                                                     });
                                                 }

                                             }}
                                        >
                                            <Img src={`${THUMBNAIL_BASE_URL}/tag/${tag.id}/${tag.thumbnailPath}`} className={`w-100 h-100`}/>
                                        </div>
                                    </div>
                                    <div className={`d-flex flex-column flex-grow-1`}>
                                        <div style={{userSelect:"all"}} >
                                            <FormLabel>
                                                이름
                                            </FormLabel>
                                            <InputGroup size={`sm`}>
                                                <FormControl value={tag.name}
                                                             onChange={e=>{
                                                                 this.setState({tag:{...tag, name:e.target.value}})

                                                             }}
                                                />
                                                <Button variant={"outline-primary"}
                                                        onClick={e=>{
                                                            axios.post(`/tag/update`,
                                                                tag
                                                            ).then(r=>{
                                                                notificationStore.notification('저장')
                                                            })
                                                        }}
                                                >
                                                    저장
                                                </Button>
                                            </InputGroup>

                                        </div>
                                        <div style={{userSelect:"auto"}}>
                                            <FormLabel onClick={()=>{
                                                if(tag.outerUniqueKey.startsWith('FC2-ACTOR-')) {
                                                    window.open(`https://db.msin.jp/page/actress?id=${tag.outerUniqueKey.replace('FC2-ACTOR-','')}`)
                                                }
                                            }}>
                                                외부 키
                                            </FormLabel>
                                            <InputGroup size={`sm`}>
                                                <FormControl value={tag.outerUniqueKey}
                                                             onChange={e=>{
                                                                 this.setState({tag:{...tag, outerUniqueKey:e.target.value}})

                                                             }}
                                                />
                                                <Button variant={"outline-primary"}
                                                        onClick={e=>{
                                                            axios.post(`/tag/update`,
                                                                tag
                                                            ).then(r=>{
                                                                notificationStore.notification('저장')
                                                            })
                                                        }}
                                                >
                                                    저장
                                                </Button>
                                            </InputGroup>

                                        </div>
                                        <div>
                                            <FormLabel>
                                                번역
                                            </FormLabel>
                                            <InputGroup size={`sm`}>
                                                <FormControl value={tag.translateName}
                                                             onChange={e=>{
                                                                 this.setState({tag:{...tag, translateName:e.target.value}})

                                                             }}
                                                />
                                                <Button variant={"outline-primary"}
                                                        onClick={e=>{
                                                            axios.post(`/tag/update`,
                                                                tag
                                                            ).then(r=>{
                                                                notificationStore.notification('저장')
                                                            })
                                                        }}
                                                >
                                                    저장
                                                </Button>
                                            </InputGroup>

                                        </div>
                                        <div>
                                            <FormLabel>
                                                설명
                                            </FormLabel>
                                            <InputGroup size={`sm`}>
                                                <FormControl value={tag.description}
                                                             as={"textarea"}

                                                             onChange={e=>{
                                                                 this.setState({tag:{...tag, description:e.target.value}})
                                                             }}
                                                />
                                                <Button variant={"outline-primary"}
                                                        onClick={e=>{
                                                            axios.post(`/tag/update`,
                                                                tag
                                                            ).then(r=>{
                                                                notificationStore.notification('저장')
                                                            })
                                                        }}
                                                >
                                                    저장
                                                </Button>
                                            </InputGroup>

                                        </div>
                                    </div>
                                </div>
                                <div className={`overflow-hidden w-100`}>

                                    <div className={Styles.MediaList}>
                                        {
                                            tagMediaStore.files.map((file,idx)=>
                                                <div style={{minWidth:300,maxWidth:300}} className={`pe-3`} key={`STORAGE_HISTORY_${idx}`}>
                                                    <Media file={file}
                                                           onClick={(file)=>{

                                                           }}
                                                           dateLabel={`${moment(file?.created).fromNow()}`}
                                                    />
                                                </div>
                                            )

                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </ContentWrap>
        );
    }
}

ActorManageView.propTypes = {};

export default ActorManageView;
