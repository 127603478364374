import React from 'react';
import ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from 'react-router-dom';
import appStore from '../store/AppStore';
import {HISTORY} from "../helper/HistoryHelper";
import {onMessageListener, requestForToken} from "../firebase";
import axios from "axios";
import {USER_TYPE} from "../const/AppConst";
import Styles from './MainViewFrame.module.scss'
import MobileNavBar from "../components/layout/MobileNavBar";
import DetailView from "./media/DetailView";
import StatusBar from "../components/layout/StatusBar";
import SideBar from "../components/layout/SideBar";
import MainView from "./media/MainView";
import WaitListView from "./media/WaitListView";
import MediaManageView from "./media/MediaManageView";
import SpriteThumbnailModal from "../components/modal/SpriteThumbnailModal";
import ActorManageView from "./tag/ActorManageView";
import {MediaDeleteModal} from "../components/modal/MediaDeleteModal";

const modalRoot = document.getElementById('modal');
const DragLayerPortal = ({ children }) => ReactDOM.createPortal(children, modalRoot);

@observer
class MainViewFrame extends React.Component {

    componentDidMount() {
        let store = this;
        if(appStore.isLogin ){
            requestForToken && requestForToken()
                .then((currentToken) => {
                    if (currentToken) {
                        console.log('current token for client: ', currentToken);
                        axios.post(
                            '/user/registration-push-token',
                            {
                                token:currentToken,
                                type:'WEB'
                            }
                        );
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            onMessageListener && onMessageListener()
                .then((payload) => {
                })
                .catch((err) => console.log('failed: ', err));
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(appStore.currentPath != HISTORY.location.pathname){
            appStore.currentPath = HISTORY.location.pathname;
        }
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className={`d-flex flex-column flex-default flex-column`} >
                {(!appStore.isLogin || !appStore.isAdmin) && <Redirect to={`/login`}/>}
                <StatusBar/>
                <div className="d-flex flex-default flex-row">
                    <SideBar/>
                    <div className={`d-flex flex-column flex-default justify-content-center ${Styles.Content}`}>
                        <Switch>
                            <Route path="/main/folder/:folderId" component={DetailView}/>
                            <Route path="/main/file/:folderId/:fileId" component={DetailView}/>
                            <Route path="/main/wait/list" component={WaitListView}/>
                            <Route path="/main/actor/manage" component={ActorManageView}/>
                            <Route path="/main/media/manage" component={MediaManageView}/>

                            <Route path="/main" component={MainView}/>
                            <Redirect exact from="/main" to="/main"/>
                        </Switch>
                        <MobileNavBar/>
                    </div>
                </div>
                <MediaDeleteModal/>
                <SpriteThumbnailModal/>
            </div>
        );

    }
}

export default MainViewFrame;
