import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import Styles from './Player.module.scss'
import {observer} from "mobx-react";
import {playerStore} from "../../store/PlayerStore";
import {isMobile} from "react-device-detect";
import CommonHelper from "../../helper/CommonHelper";
import {Button, InputGroup, Image} from "react-bootstrap";
import {
    MdFullscreen,
    MdFullscreenExit, MdOutlineForward10,
    MdOutlineForward30,
    MdOutlineForward5, MdOutlinePlaylistAdd, MdOutlinePlaylistRemove,
    MdPause,
    MdPlayArrow, MdThumbDown, MdThumbUp
} from "react-icons/md";
import {Slider} from 'antd';
import {Img} from 'react-image'
import {ENCODE_BASE_URL, THUMBNAIL_BASE_URL} from "../../const/AppConst";
import {VscScreenFull, VscScreenNormal} from "react-icons/vsc";
import {TbRewindBackward10, TbRewindForward10, TbRewindForward30, TbRewindForward5} from "react-icons/tb";

import {mediaDeleteStore} from "../modal/MediaDeleteModal";
import axios from "axios";
import {RiMovie2Line} from "react-icons/ri";
import {goBack} from "../../helper/HistoryHelper";
import {mediaStore} from "../../store/MediaStore";

@observer
class Player extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        let {url} = this.props;
        let store = playerStore;
        let file = playerStore.selectedFile;

        return (
            <div className={`d-flex flex-column flex-grow-1`}>

                <div className={`${Styles.Player} ${store.isCinema ? Styles.Cinema : ''}`}
                     onMouseLeave={store.handleControlsHide}
                     onMouseMove={store.handleControlsShow}
                     onClick={store.handleControlsShow}
                     ref={store.playerRef}
                >
                    <ReactPlayer
                        url={url}
                        ref={store.inputEl}
                        controls={isMobile}
                        config={{
                            attributes: {
                                accept: 'video/*,.mkv',
                                controlsList: 'nodownload',
                            },
                            file: {}
                        }}
                        playing={store.isPlaying}
                        // playing={false}
                        pip={store.isPip}
                        // volume={parseFloat(store.volume)}
                        volume={0}
                        onReady={store.handleReady}
                        onProgress={store.handleProgress}
                        onEnded={store.handleEnded}
                        onDuration={store.handleDuration}
                        muted={store.isMute}
                        onError={store.handleError}
                        onBuffer={store.handleBuffer}
                        onBufferEnd={store.handleBufferEnd}
                        width={"100%"}
                        height={"100%"}
                        playsinline={true}
                    />
                    <div className={`${Styles.ControlContainer}`}>
                        <div className={`${Styles.Controls} ${store.isPlay && store.isControlsHide ? `d-none` : ''}`}>
                            <div className={Styles.Header}>
                                <div>
                                    {file.name}
                                </div>
                                <div className={`d-flex`}>
                                    {
                                        appStore.isAdmin &&
                                        <div>
                                            <div className={Styles.Button}
                                                 onClick={() => {
                                                     let store = this;
                                                     axios.post('/media/encode', file, {
                                                         baseURL: ENCODE_BASE_URL
                                                     }).then(r => {
                                                     })
                                                 }}
                                            >
                                                <RiMovie2Line size={`1.5rem`} className={`me-1`}/>인코딩
                                            </div>
                                        </div>
                                    }
                                    {
                                        appStore.isAdmin &&
                                        <div>
                                            <div className={Styles.Button}
                                                 onClick={() => {
                                                     mediaDeleteStore.open(
                                                         file,
                                                         ()=> {
                                                             mediaStore.fileMap.delete(file.id)
                                                         }
                                                     )
                                                 }}
                                            >
                                                <MdOutlinePlaylistRemove size={`1.5rem`} className={`me-1`}/>삭제
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={`w-100`}>
                                <div className={Styles.Option}>
                                    <div className={Styles.LeftControls}>
                                        <div>
                                            <div onClick={store.handlePlay} className={Styles.Button}>
                                                {
                                                    store.isPlaying ?
                                                        <MdPause size={`1.5rem`}/>
                                                        :
                                                        <MdPlayArrow size={`1.5rem`}/>
                                                }
                                            </div>
                                        </div>
                                        <div className={Styles.Time}>
                                            {
                                                store.isLoaded &&
                                                `${CommonHelper.millisecondConvertTime(store.playedSeconds)} / ${CommonHelper.millisecondConvertTime(store.playedDuration)}`
                                            }
                                        </div>

                                    </div>
                                    <div className={Styles.RightControls}>
                                        <div className={`d-flex justify-content-end`}>

                                            <div>
                                                <div className={Styles.Button} variant={"secondary"}
                                                     onClick={() => {

                                                     }}
                                                >
                                                    <MdOutlinePlaylistAdd size={`1.5rem`} className={`me-1`}/>저장
                                                </div>
                                            </div>
                                            <div>
                                                <InputGroup>
                                                    <div className={Styles.Button}
                                                         style={{color: file?.isLike ? 'var(--bs-info)' : '#fff'}}
                                                         onClick={() => {
                                                             store.toggleLike(file)
                                                         }}
                                                    >
                                                        <MdThumbUp size={`1rem`} className={`me-1`}/>{file?.likeCount}
                                                    </div>
                                                    <div className={Styles.Button}
                                                         style={{color: file?.isHate ? 'var(--bs-danger)' : '#fff'}}
                                                         onClick={() => {
                                                             store.toggleHate(file)
                                                         }}
                                                    >
                                                        <MdThumbDown size={`1rem`} className={`me-1`}/>{file?.hateCount}
                                                    </div>

                                                </InputGroup>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div className={`d-flex`}>
                                            <div className={Styles.Volume}>
                                                <div className={Styles.Button} onClick={store.toggleMuted}>
                                                    {store.volumeIcon}
                                                </div>
                                                <div className={Styles.VolumeBarContainer}>
                                                    <Slider value={store.volume} style={{width: '100%'}}
                                                            tooltip={{open: false}}
                                                            step={0.01}
                                                            max={1}
                                                            onChange={value => {
                                                                store.handleVolumeChange(value)
                                                            }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`ps-2 d-flex justify-content-center align-items-center`}>
                                                <div className={Styles.Button}
                                                     onClick={() => store.changeSeek(store.playedSeconds - 10)}>
                                                    <TbRewindBackward10 size={`1.5rem`}/>
                                                </div>
                                                <div className={Styles.Button}
                                                     onClick={() => store.changeSeek(store.playedSeconds + 5)}>
                                                    <TbRewindForward5 color={'#fff'} size={`1.5rem`}/>
                                                </div>
                                                <div className={Styles.Button}
                                                     onClick={() => store.changeSeek(store.playedSeconds + 10)}>
                                                    <TbRewindForward10 color={'#fff'} size={`1.5rem`}/>
                                                </div>
                                                <div className={Styles.Button}
                                                     onClick={() => store.changeSeek(store.playedSeconds + 30)}>
                                                    <TbRewindForward30 color={'#fff'} size={`1.5rem`}/>
                                                </div>
                                            </div>

                                            {
                                                !store.isFullScreeMode &&
                                                <div>
                                                    <div className={Styles.Button} onClick={store.toggleCinema}>
                                                        {
                                                            store.isCinema ?
                                                                <VscScreenNormal color={'#fff'} size={`1.5rem`}/>
                                                                :
                                                                <VscScreenFull color={'#fff'} size={`1.5rem`}/>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !store.isCinema &&
                                                <div>
                                                    <div className={Styles.Button} onClick={store.toggleFullScreen}>
                                                        {
                                                            !store.isFullScreeMode ?
                                                                <MdFullscreen color={'#fff'} size={`1.5rem`}/>
                                                                :
                                                                <MdFullscreenExit color={'#fff'} size={`1.5rem`}/>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={Styles.SeekBarContainer} ref={store.seekBarRef}
                                     onMouseMove={(e)=>{
                                         let bounding = store.seekBarRef.current.getBoundingClientRect()
                                         store.offsetWidth = store?.seekBarRef?.current?.offsetWidth;
                                         store.offsetX = e.clientX - bounding.x;
                                     }}
                                >
                                    <Slider value={store.playedSeconds||0} className={Styles.SeekBar}
                                            tooltip={{open: false}}
                                            step={0.1}
                                            max={store.duration}
                                            onChange={e => {
                                                store.handleSeekBarChange(e)
                                            }}
                                    />
                                    {
                                        file?.isCreatedSpriteThumbnail &&
                                            <div className={Styles.SpriteThumbnail} style={{left:store.left}}>
                                                <Img src={`${THUMBNAIL_BASE_URL}/${file?.folder?.id}/${file?.id}/sprite/${store.thumbnailNumber}.jpg`}/>
                                            </div>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className={Styles.MobilePanel}>
                    <div>
                        <Button variant={"secondary"} className={Styles.Button} onClick={() => {

                        }}>
                            <MdOutlinePlaylistAdd size={`1.5rem`} className={`me-1`}/>저장
                        </Button>
                    </div>
                    <div>
                        <InputGroup>
                            <Button variant={"secondary"} className={Styles.Button}
                                    style={{color: file?.isLike ? 'var(--bs-info)' : '#fff'}}
                                    onClick={() => {
                                        store.toggleLike(file)
                                    }}
                            >
                                <MdThumbUp size={`1rem`} className={`me-1`}/>{file?.likeCount}
                            </Button>
                            <Button variant={"secondary"} className={Styles.Button}
                                    style={{color: file?.isHate ? 'var(--bs-danger)' : '#fff'}}
                                    onClick={() => {
                                        store.toggleHate(file)
                                    }}
                            >
                                <MdThumbDown size={`1rem`} className={`me-1`}/>{file?.hateCount}
                            </Button>

                        </InputGroup>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        );
    }
};
export default Player;
