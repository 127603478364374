import React, {Component} from 'react';
import {observer} from 'mobx-react';

import ContentWrap from "../../components/layout/ContentWrap";

import {Button, Col, Container, Nav, Row, Tab, Tabs} from "react-bootstrap";

import axios from "axios";

@observer
class WaitListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            waitList : []
        }

    }

    componentDidMount() {
        axios.post(`/media/wait/list`, {})
            .then(({data})=>this.setState({waitList:data}))
    }


    render() {

        return (
            <ContentWrap>

                <Tab.Container defaultActiveKey="wait">
                    <Nav variant="underline" activeKey="1">
                        <Nav.Item>
                            <Nav.Link eventKey="wait" >
                                <div className={`ps-3 pe-3`}>
                                    Wait
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="manual">
                                <div className={`ps-3 pe-3`}>
                                    Manual
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="wait">
                            <div>
                                {
                                    this.state.waitList.map(file=>
                                        <div onClick={()=>{
                                            axios.post(`/media/upload`, file)
                                        }}>
                                            {file.name}
                                        </div>
                                    )
                                }
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="manual"></Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </ContentWrap>
        );
    }
}

WaitListView.propTypes = {};

export default WaitListView;
