import {action, computed, makeObservable, observable, values, toJS} from 'mobx';
import Cookies from 'js-cookie';
import * as moment from 'moment';
import axios from 'axios';
import {toast} from "react-toastify";
import {navigate} from '../helper/HistoryHelper';

const AUTH = 'authKey';
const ID = 'id';
const EMAIL = 'email';
const IS_SAVE = 'save';
const USER_ID = 'username';
const USER_NAME = 'name';
const IS_ADMIN = 'admin';

export const CookieKeys = {AUTH, ID, USER_ID, USER_NAME, IS_ADMIN, EMAIL, IS_SAVE};
axios.interceptors.response.use((response) => response, (error) => {
    let response = error.response;
    if(!response || !response.data) {
        toast.error(error);
        throw error;
    }

    toast.error(response.data.message)

    if(response.status === 401){
        appStore.isLogin = false;
        navigate("/login")
    }
    throw error;
});

/**
 * 앱 전반에 관련된 상태
 */
class AppStore {

    constructor() {
        makeObservable(this);
        setInterval(
            ()=>this.now = moment()
            ,1000
        )
    }

    failedPath = '/';

    @observable
    now = moment();

    @observable
    isLogin = false;

    @observable
    loginId = '';

    @observable
    user

    @observable
    id = '';

    @observable
    avatar = '';

    @observable
    name = Cookies.get(CookieKeys.USER_NAME);

    @observable
    permissions = [{id: '/login'}];

    @observable
    isAdmin = false;

    @observable
    currentPath = '/';

    @observable
    previousPath = '/';

    @observable
    sideBarOpen = false;

    @observable
    isSessionChecked = false;

    @observable
    username = '';

    @observable
    type = '';

    @observable
    isUpdatingSession = false;

    @observable
    status = 'ACTIVE';

    @observable
    isResizing = false;

    @observable
    includeLabsUser;

    @action.bound
    updateSession({id, name, avatar, type, status}) {
        let user = {id, name, avatar, type};
        this.id = id;
        this.avatar = avatar;
        this.name = name;
        this.type = type;
        this.status = status;
        this.user = user;
        Cookies.set(CookieKeys.ID, id);
        Cookies.set(CookieKeys.USER_NAME, name);


        if (type === 'ADMIN' || type === 'SUPER'){
            this.isAdmin = true;
        }else{
            this.isAdmin = false;
        }
        this.isLogin = true;
    }



    @action.bound
    clearSession() {
        this.userId = '';
        this.userEmail = '';
        this.name = '';
        this.userProfileUrl = '';
        Cookies.remove(CookieKeys.ID);
        Cookies.remove(CookieKeys.USER_NAME);
        this.eventTrackFn = undefined;
        this.isLogin = false;
    }


    /**
     * 로그인을 요청한다.
     * @param form 로그인폼
     * @param success 성공콜백
     * @paraam fail 실패콜백
     */
    @action.bound
    async fetchLogin({form, success, fail, beforeSend, complete}) {
        const self = this;
        beforeSend && beforeSend();
        this.isUpdatingSession = true;
        axios.post('/user/login', form, { withCredentials: true }).then(response => {
            const data = response?.data;
            const {id, name, type, avatar, status} = data;
            this.updateSession({
                id,
                name,
                type,
                avatar,
                status,
            })
            success && success(data);
            this.isUpdatingSession = false;
            navigate("/login")
        }).catch(function (error) {
            fail && fail(error)
            self.isUpdatingSession = false;
        }).finally(() => {
            complete && complete();
        });
    }

    /**
     * 로그아웃
     */
    @action.bound
    fetchLogout() {
        axios.post('/user/logout',
            {
                user: { id: appStore.userId }
            }
        ).then( () => {
            window.localStorage.clear("cookie");
            this.clearSession();
            navigate('/login')
        })
    }

    /**
     * 로그인 세션이 쿠키에 남아있을때 세션정보를 재 로드한다.
     */
    @action.bound
    async fetchSessionInfo() {
        try {
            let store = this;
            store.isUpdatingSession = true;
            const response = await axios.post('/user/session-touch',{})
                .catch(e => console.log(e))
                .finally( r => {
                store.isUpdatingSession = false;
            });
            if(!response.data){
                this.clearSession()
            } else {
                let {id, name, type, username, agent, chatUser, status} = response?.data;
                this.updateSession({
                    id,
                    name,
                    type,
                    username,
                    agent,
                    chatUser,
                    status
                })
            }

        } catch (e) {
            this.clearSession();
        } finally {
            this.isSessionChecked = true;
        }
    }

}

const appStore = new AppStore();
window.appStore = appStore;
export default appStore;
