import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from './DetailView.module.scss'
import axios from "axios";
import {makeObservable, observable} from "mobx";
import Player from "../../components/media/Player";
import {playerStore} from "../../store/PlayerStore";
import {Button, Container, Image} from "react-bootstrap";
import MobileHeader from "../../components/layout/MobileHeader";
import ContentWrap from "../../components/layout/ContentWrap";
import {MdArrowDropUp, MdOutlinePlaylistAdd, MdOutlinePlaylistPlay, MdThumbDown, MdThumbUp} from "react-icons/md";

import {FaPlus, FaUser} from "react-icons/fa";

import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

import AvatarTag from "../../components/layout/tag/AvatarTag";


@observer
class DetailView extends Component {

    constructor(props, context) {
        super(props, context);
        makeObservable(this);
        this.state = {
            isOpen : false
        }
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.insertHistory);
        // window.addEventListener('unload', 콜백함수);
        let { match:{ params } } = this.props;
        let {folderId, fileId} = params;
        this.load(folderId, fileId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevParams = prevProps.match.params;
        let nextParams = this.props.match.params;
        if((prevParams.folderId !== nextParams.folderId)||(prevParams.fileId !== nextParams.fileId)){
            this.load(nextParams.folderId, nextParams.fileId);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.insertHistory);
        this.insertHistory()
    }

    load(folderId, fileId){
        let store = this;
        axios.post(`/media/detail/${folderId}`,{})
            .then(res=>{
                let folder = res.data;
                if(folder.files && folder.files.length > 0){
                    let file;
                    if(fileId){
                        file = folder.files.find(file=>file.id === parseInt(fileId))
                    }else{
                        file = folder.files[0];

                    }
                    this.folder = folder;
                    this.files = folder.files;
                    this.file = file
                    playerStore.setFile(file, folder);
                }

            })
            .catch(err=>{
            });
    }

    insertHistory = e => {
        axios.post(
            `/media/history/insert`,
            {
                ...this.file,
                history:{
                    playedSeconds : playerStore.playedSeconds
                }
            }
        )

    }

    @observable
    file;

    @observable
    folder;

    @observable
    files = [];

    render() {
        let selectedFile = this.file;
        let files = this.files;
        let folder = this.folder;
        let actors = selectedFile?.tags?.filter(tag=>tag?.type === 'ACTOR') || [];
        return (
            <ContentWrap>
                <MobileHeader title={selectedFile?.name}/>
                <div className={Styles.DetailView}>
                    <Container className={Styles.DetailContainer}>

                        <div className={Styles.PlayerContainer}>
                            {
                                selectedFile &&
                                <Player url={'/api/media/'+selectedFile?.realPath}/>
                            }
                        </div>
                        <div className={Styles.DetailInfo}>
                            <div className={Styles.MobilePanel}>
                                <div>
                                    <div className={Styles.Button}>
                                        <MdOutlinePlaylistAdd size={`1.5rem`} className={`me-1`}/>저장
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.Button}>
                                        <MdThumbUp size={`1rem`} className={`me-1`}/>{selectedFile?.likeCount} 좋아요
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.Button}>
                                        <MdThumbDown size={`1rem`} className={`me-1`}/>{selectedFile?.hateCount} 싫어요
                                    </div>
                                </div>
                            </div>
                            {
                                actors.length > 0 &&
                                <div className={Styles.ActorList}>
                                    <div className={`d-flex align-items-center`}>
                                        <FaUser className={`me-2`}/> 배우정보
                                    </div>
                                    <div className={Styles.List}>
                                        {
                                            actors.map(tag=>
                                                <AvatarTag tag={tag}/>
                                            )
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                        <div>

                        </div>
                        <div className={`${Styles.ListWrap} ${this.state.isOpen ? Styles.Open : ''}`}>
                            {
                                files.filter(file=>file?.id != selectedFile.id).length > 0 &&
                                    <>
                                        <div className={Styles.Header}
                                             onClick={()=>{
                                                 this.setState({isOpen:!this.state.isOpen})
                                             }}
                                        >
                                            <div className={Styles.Title}>
                                                <MdOutlinePlaylistPlay size={`1.5rem`} className={`me-2`}/> 관련 영상
                                            </div>
                                            <div className={Styles.Link}>
                                                {
                                                    this.state.isOpen ?
                                                        <IoIosArrowDown size={`1.5rem`}/>
                                                        :
                                                        <IoIosArrowUp size={`1.5rem`}/>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${Styles.List} `}>
                                            {
                                                files.filter(file=>file?.id != selectedFile.id).map(file=>
                                                    <div style={{minWidth:300, maxWidth:300}} className={`pe-3`}>
                                                        <Media file={{...file, folder}}/>
                                                    </div>
                                                )

                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </Container>
                </div>
            </ContentWrap>
        );
    }
}

export default DetailView;
