import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {mediaStore} from "../../store/MediaStore";
import ContentWrap from "../../components/layout/ContentWrap";
import InfiniteScroll from "react-infinite-scroller";

import {Button, Col, Container, FormControl, InputGroup, Nav, Row, Tab, Tabs, Form, Dropdown} from "react-bootstrap";
import Styles from './MediaManageView.module.scss'
import axios from "axios";
import Player from "../../components/media/Player";
import {playerStore} from "../../store/PlayerStore";
import {MdClosedCaption, MdDeleteOutline, MdImage} from "react-icons/md";
import {IoMdImages} from "react-icons/io";
import {spriteThumbnailStore} from "../../components/modal/SpriteThumbnailModal";
import {FaPlus, FaPlusCircle, FaUser} from "react-icons/fa";
import AvatarTag from "../../components/layout/tag/AvatarTag";

const SearchDropdown = observer(({title, id, store, trueLabel = '있음', falseLabel = '없음'}) => {
    let searchCondition = store.searchCondition;
    let value = searchCondition.get(id);
    return(
        <div>
            {title}
            <Dropdown className="d-inline mx-2" >
                <Dropdown.Toggle size={'sm'} variant={'outline-secondary'} as={Button}>
                    {
                        value === undefined ? '전체' : value ? trueLabel:falseLabel
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>{searchCondition.set(id, undefined)}}>전체</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{searchCondition.set(id, true)}}>{trueLabel}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{searchCondition.set(id, false)}}>{falseLabel}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
});

@observer
class MediaManageView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: undefined
        }

    }

    componentDidMount() {
        let store = mediaStore;
        store.scrollRef.current.scrollTo(0, store.scrollTop)
        if(store.isInit){
            store.debounceRefresh();
        }
    }

    reload(){
        axios.post(`/media/detail/${this.state.file?.id}`)
            .then(({data: file})=>{
                this.setState({file})
                mediaStore.fileMap.set(file.id, file)
            })
    }


    render() {
        let store = mediaStore;
        let file = this.state.file;
        let tags = this.state.file?.tag;
        let actors = this.state.file?.tags.filter(tag=>tag.type==='ACTOR');

        return (
            <ContentWrap>
                <div className={Styles.SearchBar}>
                    <div className={`p-0 d-flex justify-content-between`}>
                        <div className={`d-flex text-nowrap`}>
                            <SearchDropdown title={<MdDeleteOutline size={`1.5rem`}/>} id={'isDeleted'} store={store} trueLabel={'삭제'} falseLabel={'사용'}/>
                            <SearchDropdown title={<MdImage size={`1.5rem`}/>} id={'haveThumbnail'} store={store}/>
                            <SearchDropdown title={<IoMdImages size={`1.5rem`}/>} id={'haveSpriteThumbnail'} store={store}/>

                        </div>
                        <div className={`d-flex flex-grow-1 text-nowrap`}>
                            <InputGroup>
                                <FormControl size={'sm'}
                                             value={this.state.keyword}
                                             onChange={e => {
                                                 this.setState({keyword: e.target.value});
                                             }}
                                             onKeyDown={e => {
                                                 if (e.key === 'Enter') {
                                                     store.searchCondition.set("keyword", this.state.keyword)
                                                     store.debounceFetch(true);
                                                 }
                                             }}
                                />
                                <Button variant={"outline-light"} size={'sm'}
                                        onClick={() => {
                                            store.searchCondition.set("keyword", this.state.keyword)
                                            store.debounceFetch(true);
                                        }}
                                >
                                    검색
                                </Button>
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={`d-flex flex-grow-1 overflow-hidden`}>

                    <div className={Styles.List}
                         ref={store.scrollRef}
                         onScroll={store.onScrollHandler}
                    >
                        <InfiniteScroll
                            loadMore={(page) => {
                                store.debounceFetch(false)
                            }}
                            hasMore={store.files.length < store.count}
                            loader={
                                <div key={"a"}>
                                </div>
                            }
                            useWindow={false}
                            threshold={5}
                        >

                            {
                                !store.isFetching && store.files.length < 1 &&
                                <div className={`align-items-center justify-content-center d-flex p-5`}>
                                    검색 결과가 없습니다.
                                </div>
                            }
                            {
                                store.files.map(file => {
                                    return (
                                        <div className={`${Styles.File} ${this.state.file?.id === file.id ? Styles.Active : ''} ${!file.isActive ? Styles.Deleted : ''}`}
                                             onClick={()=>{
                                                 // if(file.isActive) {
                                                     playerStore.setFile(file)
                                                     this.setState({file})
                                                 // }
                                             }}
                                        >
                                            <div className={Styles.Icons}>
                                                {
                                                    file.thumbnailPath &&
                                                    <MdImage/>
                                                }
                                                {
                                                    file.subtitlePath &&
                                                    <MdClosedCaption/>
                                                }
                                                {
                                                    file.isCreatedSpriteThumbnail &&
                                                    <IoMdImages/>
                                                }
                                            </div>

                                            <div className={Styles.Name}>
                                                {file.name}

                                            </div>

                                        </div>
                                    )
                                })
                            }

                        </InfiniteScroll>
                    </div>
                    {
                        file &&
                            <div className={Styles.Content}>
                                <div className={Styles.Player}>
                                    <Player url={'/api/media/'+file?.realPath}/>
                                </div>

                                <div className={Styles.RightPanel}>
                                    <div className={Styles.Title}>
                                        {file.name}
                                    </div>
                                    <div className={Styles.ActorList}>
                                        <div className={`d-flex align-items-center`}>
                                            <FaUser className={`me-2`}/> 배우정보
                                        </div>
                                        <div className={Styles.List}>
                                            <div className={`me-2`}>
                                                <FaPlusCircle size={`5rem`}/>
                                            </div>
                                            {
                                                actors.length > 0 &&
                                                    actors.map(tag=>
                                                        <AvatarTag tag={tag} callback={()=>this.reload()}/>
                                                    )

                                            }
                                        </div>
                                    </div>
                                    <div>

                                        <Button onClick={()=>{
                                            spriteThumbnailStore.open(file)
                                        }}>
                                            <IoMdImages/>
                                        </Button>
                                        <Button onClick={()=>{
                                            axios.post(
                                                `/media/sprite/thumbnail/create`,
                                                file
                                            )
                                        }}>
                                            <IoMdImages/> 생성
                                        </Button>
                                    </div>


                                </div>


                            </div>
                    }
                </div>
            </ContentWrap>
        );
    }
}

MediaManageView.propTypes = {};

export default MediaManageView;
