import React from 'react';
import {observer} from 'mobx-react';
import ModalBase from "./ModalBase";
import Styles from './SpriteThumbnailModal.module.scss'
import {computed, makeObservable, observable, toJS} from "mobx";
import axios from "axios";
import {Img} from "react-image";
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import {Button} from "react-bootstrap";
import notificationStore from "../../store/NotificationStore";
import {ProgressBar} from "react-loader-spinner";

class SpriteThumbnailStore{
    constructor() {
        makeObservable(this);
    }

    @observable
    isFetching = false;

    @observable
    show = false;

    @observable
    spriteThumbnails = [];

    @observable
    selected;

    @observable
    file;

    scrollRef = React.createRef();

    @observable
    startIndex = 0;

    @observable
    viewCount = 10;

    @computed
    get viewSpriteThumbnails(){
        return toJS(this.spriteThumbnails).sort().slice(this.startIndex, this.startIndex + this.viewCount + 1)
    }

    open = (file) => {
        let store = this;
        store.isFetching = true;
        axios.post(`/media/sprite/thumbnail/list`,file)
            .then(res=>{
                store.spriteThumbnails = res.data
                store.isFetching = false;
            })
        store.file = file;
        store.show = true;
    }

    close = () =>{
        let store = this;
        store.show = false;
        store.spriteThumbnails = [];
        store.startIndex = 0;
        store.viewCount = 10;
        store.selected = undefined
        store.file = undefined
    }
}

const spriteThumbnailStore = new SpriteThumbnailStore()

@observer
class SpriteThumbnailModal extends React.Component {

    render() {
        let store = spriteThumbnailStore;
        return (
            <ModalBase
                style={{zIndex: '99999'}}
                centered
                show={store.show}
                onHide={store.close}
                size={'xl'}
            >
                <div className={Styles.Content}>
                    {
                        store.selected &&
                        <div className={Styles.Detail}>
                            <div className={Styles.Image}>
                                <Img src={`${THUMBNAIL_BASE_URL}${store.selected}`}/>
                            </div>
                            <div className={Styles.Buttons}
                                 onClick={() => {
                                     axios.post(
                                         `/media/thumbnail/selected`,
                                         {
                                             media: store.file,
                                             thumbnailPath: store.selected
                                         }
                                     ).then(res => {
                                         notificationStore.notification("썸네일이 선택되었습니다.")
                                     })
                                 }}
                            >
                                <Button variant={"outline-secondary"}>썸네일 선택</Button>
                            </div>
                        </div>
                    }
                    <div className={`d-flex flex-column`}>
                        <div className={`p-1`}>
                            총 {store.spriteThumbnails.length}개
                        </div>
                        <div className={Styles.List} ref={store.scrollRef}
                             onScroll={e => {
                                 store.viewCount = parseInt(e.target.clientHeight / 180);
                                 store.startIndex = parseInt(e.target.scrollTop / 180);
                             }}
                        >
                            <div style={{width: 320, height: store.spriteThumbnails.length * 180, position: "relative"}}>
                                {
                                    store.viewSpriteThumbnails.map((url, idx) => {
                                        let top = (180 * (store.startIndex + idx))

                                        return (
                                            <div className={Styles.Item}
                                                 style={{top}}
                                                 onClick={() => {
                                                     store.selected = url
                                                 }}
                                            >
                                                <Img src={`${THUMBNAIL_BASE_URL}${url}`}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        {
                            store.isFetching &&
                                <div className={Styles.Loader}>
                                    <ProgressBar
                                        visible={true}
                                        height="100"
                                        width="100"
                                        color="#4fa94d"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                        }

                    </div>

                </div>
            </ModalBase>
        )
    }
}

export {SpriteThumbnailModal as default, spriteThumbnailStore};
